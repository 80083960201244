import siteSettings from "./site-settings";

export default {
  head() {
    // console.log("Current Lang:", this.currentLang);
    return {
      htmlAttrs: {
        lang: this.currentLang ?? "tr",
      },
    };
    try {
      if (this.seo && this.seo !== null && this.seo !== undefined) {
        return {
          // Title and metadata for SEO, including handling fallback values
          title: this.seo.metaTitle
            ? this.seo.metaTitle
            : this.pageTitle
            ? this.pageTitle + " | İSKİ"
            : siteSettings?.seo?.metaTitle ?? "İSKİ",
          meta: [
            this.seo.preventIndexing
              ? {
                  name: "robots",
                  content: "noindex",
                }
              : "",
            {
              hid: "description",
              name: "description",
              content:
                this.seo.metaDescription ??
                siteSettings?.seo?.metaDescription ??
                "İSKİ",
            },
            // Open Graph (OG) Metadata
            {
              hid: "og:title",
              property: "og:title",
              content: this.seo.metaTitle
                ? this.seo.metaTitle
                : this.pageTitle
                ? this.pageTitle + " | İSKİ"
                : siteSettings?.seo?.metaTitle ?? "İSKİ",
            },
            {
              hid: "og:description",
              property: "og:description",
              content:
                this.seo.metaDescription ??
                siteSettings?.seo?.metaDescription ??
                "İSKİ",
            },
            {
              hid: "og:type",
              property: "og:type",
              content: "website",
            },
            {
              hid: "og:site_name",
              name: "site_name",
              property: "og:site_name",
              content: "İSKİ",
            },
            {
              hid: "og:author",
              name: "author",
              property: "og:author",
              content: "İSKİ",
            },
            {
              hid: "og:locale",
              property: "og:locale",
              content: this.currentLang ?? "tr",
            },
            {
              hid: "og:image",
              property: "og:image",
              content:
                this.seo.metaImage?.data?.attributes?.url ??
                process.env.NUXT_ENV_BASE_URL + "/iski-og-image.png",
            },
            {
              hid: "og:url",
              property: "og:url",
              content:
                this.seo.canonicalURL ??
                process.env.NUXT_ENV_BASE_URL + this.$route.path,
            },
            {
              hid: "og:keywords",
              name: "keywords",
              property: "og:keywords",
              content:
                this.seo.keywords ?? "Istanbul Su ve Kanalizasyon Idaresi",
            },
            // Twitter Metadata
            {
              hid: "twitter:image",
              name: "twitter:image",
              property: "twitter:image",
              content:
                this.seo.metaImage?.data?.attributes?.url ??
                process.env.NUXT_ENV_BASE_URL + "/iski-og-image.png",
            },
            {
              hid: "twitter:site",
              name: "site",
              property: "twitter:site",
              content: "@istanbulbld",
            },
            {
              hid: "twitter:card",
              name: "twitter:card",
              property: "twitter:card",
              content: "summary_large_image",
            },
            {
              hid: "twitter:creator",
              name: "twitter:creator",
              property: "twitter:creator",
              content: "@istanbulbld",
            },
            {
              hid: "twitter:title",
              name: "twitter:title",
              property: "twitter:title",
              content: this.seo.metaTitle
                ? this.seo.metaTitle
                : this.pageTitle
                ? this.pageTitle + " | İSKİ"
                : siteSettings?.seo?.metaTitle ?? "İSKİ",
            },
            {
              hid: "twitter:description",
              name: "twitter:description",
              property: "twitter:description",
              content:
                this.seo.metaDescription ??
                siteSettings?.seo?.metaDescription ??
                "İSKİ",
            },
            {
              hid: "twitter:image:alt",
              name: "twitter:image:alt",
              property: "twitter:image:alt",
              content:
                this.seo.metaImage?.data &&
                !!this.seo.metaImage.data.attributes.alternativeText
                  ? this.seo.metaImage.data.attributes.alternativeText
                  : "İSKİ Og Image",
            },
          ],
        };
      } else if (this.pageTitle) {
        // Fallback metadata when SEO data is not available
        return {
          title: this.pageTitle
            ? this.pageTitle + " | İSKİ"
            : "İSKİ - Istanbul Su ve Kanalizasyon Idaresi",
          meta: [
            {
              hid: "twitter:image",
              name: "twitter:image",
              property: "twitter:image",
              content: process.env.NUXT_ENV_BASE_URL + "/iski-og-image.png",
            },
            {
              hid: "twitter:site",
              name: "site",
              property: "twitter:site",
              content: "@istanbulbld",
            },
            {
              hid: "twitter:card",
              name: "twitter:card",
              property: "twitter:card",
              content: "summary_large_image",
            },
            {
              hid: "twitter:creator",
              name: "twitter:creator",
              property: "twitter:creator",
              content: "@istanbulbld",
            },
            {
              hid: "og:type",
              property: "og:type",
              content: "website",
            },
            {
              hid: "og:site_name",
              name: "site_name",
              property: "og:site_name",
              content: "İSKİ",
            },
            {
              hid: "og:author",
              name: "author",
              property: "og:author",
              content: "İSKİ",
            },
            {
              hid: "og:locale",
              property: "og:locale",
              content: this.currentLang ?? "tr",
            },
            {
              hid: "og:image",
              property: "og:image",
              content: process.env.NUXT_ENV_BASE_URL + "/iski-og-image.png",
            },
          ],
        };
      }
    } catch (ex) {}
  },
};
